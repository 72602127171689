.section-slider, .section-slider-std {
    position: relative;
    display: inline-block;
    background: $white;
    width: 100%;
    margin: 1.25rem 0 0;
    &.nomt {
        margin-top: 0;
    }
    &.nopt {
        padding-top: 0;
    }
    .section-slide {
        height: 80vh;
    }

    .slider-overlay {
        display: inline-block;
        width: 100%;
        background: fade-out($background-darkgrey, 0.1);
        padding: 2.5rem 0;
    }

    .slider-arrow {
        position: absolute;
        top: 3.75rem;
        z-index: 8;
        height: 29px;
        cursor: pointer;
        margin-top: -29px;
        opacity: 1;
        @include media-breakpoint-up(xl){
            top: 0;
            opacity: 0.5;
        }
        @include transition();
        &.slick-prev {
            left: 3.185rem;
            top: 120px;
            @include media-breakpoint-up(xl){
                left: 100px;
                top: initial;
            }
            &:before {
                content: "";
                position: absolute;
                width: 200%;
                height: 200%;
                left: -50%;
                z-index: -1;
                background: url('assets/img/ARROW.png')no-repeat center;  
                background-size: contain;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
                @include media-breakpoint-up(xl) {
                    background-size: auto;
                }
            }
            &:hover {
                opacity: 1;
            }
        }
        &.slick-next {
            left: 3.185rem;
            @include media-breakpoint-up(xl){
                right: 100px;
                left: initial;
            }
            &:before {
                content: "";
                position: absolute;
                width: 200%;
                height: 200%;
                left: -50%;
                z-index: -1;
                background: url('assets/img/ARROW.png')no-repeat center;  
                background-size: contain;
                @include media-breakpoint-up(xl) {
                    background-size: auto;
                }
            }
            &:hover {
                opacity: 1;
            }
        }
    }

    .slick-next, .slick-prev {
        color: $white;
        width: 1.5rem;
        outline: none;
        border: 0px;
        @include media-breakpoint-up(xl){
            width: 3.75rem;
        }
    }

    ul {
        &.slickLines {
            width: auto;
            padding: 0;
            position: absolute;
            bottom: 3.125rem;
            display: none!important;
            margin: 0 auto;
            left: 50%;
            z-index: 9999;
            @include media-breakpoint-up(xl){
                display: block!important;
            }
            li {
                border-bottom: 1px solid fade-out($white, 0.6);
                margin: 3px;
                display: inline-block;
                width: 1.25rem;
                &.slick-active {
                    border-bottom: 1px solid $white;
                }
                button {
                    background: transparent;
                    color: transparent;
                    border: transparent;
                    outline: none;
                }
            }
        }
    }
}
.section-slider-std {
    margin-top: 0;
    .section-slide {
        height: 40vh;
        max-height: 400px;
    }
}
.slider-nav {
    margin-top:0.305rem;
    cursor: pointer;
    .section-slide {
        height: 5.98rem;
        margin: 0.305rem;
        -webkit-filter: brightness(25%); 
        filter: brightness(25%);
        @include transition;
        @include media-breakpoint-up(md){
            height: 6.98rem;
        }
        &.slick-current {
            -webkit-filter: brightness(100%); 
            filter: brightness(100%);
        }
    }
}

.main-slider-holder {
    .slider-content-holder {
        border-left: 1px solid $white;
        padding: 0 0 0rem 1.25rem;
        color: $white;
        @include media-breakpoint-up(xl){
            padding: 1.25rem 0 1.25rem 1.25rem;
        }
    }
    p {
        color: $white;
        padding: 0;
        margin: 0;
    }
}



