.section {
    .col-materials-holder {
        min-height: 17.44rem;
        overflow: hidden;
        position: relative;
        @include media-breakpoint-up(xl){
            min-height: auto;
        }
        #accordion {
            padding: 0;
            margin: 0;
        }
        .material-slide {
            position: absolute;
            width: 100%;
            height: 100%;
            background: grey;
            right: 0;
            list-style: none;
            cursor: pointer;
            .material-tab {
                position: absolute;
                left: 2.4rem;
                margin-left: -7.5px;
                bottom: 1.25rem;
                font-size: 11px;
                transform: rotate(270deg);
                -ms-transform: rotate(270deg);
                -moz-transform: rotate(270deg);
                -webkit-transform: rotate(270deg);
                -o-transform: rotate(270deg);
                line-height: 15px;
                height: 15px;
                width: 15px;
                overflow: visible;
                white-space: nowrap;
                h4 {
                    color: $white;
                    margin: 0;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }
            }
            i {
                transform: rotate(0deg);
                padding: 1.25rem;
                text-align: center;
                width: 5rem;
                color: $white;
                @include transition();
                &.rotate {
                    transform: rotate(180deg);
                }
            }
            &:last-child {
                i {
                    display: none;
                }
            }
        }
        $elements: 10;
        $offset: -5;
        @for $i from 0 to $elements {
            .material-slide:nth-child(#{$i}) {
                background: rgb($i, $i, $i);
                // left: $offset+rem;
                width: calc( 100% - #{$offset}rem );
                width: -webkit-calc( 100% - #{$offset}rem );
                width: -moz-calc( 100% - #{$offset}rem );
            }
            $offset: $offset +5;
        }
    }
}

.mat-overlay {
  display: none;
  background: fade-out(black, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  .mat-holder {
    background: white;
    max-height: 60vh;
    overflow-y:scroll;
    // text-align: center;
    // padding: 30px;
    // padding-bottom: 0;
    .loading{
      width: 100%;
      display: block;
      text-align: center;
    }
    p {
      padding-top: 10px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
