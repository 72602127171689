$linewidth: 0.0625rem;
$linespace: 2.6625rem;

.static-title {
    border-right: none;
    h1 {
        color: $font-lightgrey;
        text-align: center;
        @include media-breakpoint-up(lg){
            text-align: left;
        }
    }
    @include media-breakpoint-up(lg){
        border-right: 1px solid $menu-grey;
        text-align: center;
    }
}

#trunk {
    padding: 0;
    text-transform: center;
    margin: 0 1.25rem;
    @include media-breakpoint-up(lg){
        margin: -62px 1.25rem 0;
    }
    li {
        width: 100%;
        display: block;
        >ul {
            &:before {
                content: '';
                position: absolute;
                width: 80%;
                left: 50%;
                margin-left: -40%;
                height: 0;
                background: $menu-grey; 
                margin-bottom:$linespace;
                @include media-breakpoint-up(lg){
                    height: $linewidth;
                }
            }
        }
        ul {
            position: relative;
            padding: 0;
            width: 100%;
            &#main-branch{
                display: inline-block;
                @include media-breakpoint-up(lg){
                    border-left: none;
                }
            }
            li {
                display: inline-block;
                margin-left: -1px;
                width: 100%;
                float: left;
                @include media-breakpoint-up(lg) {
                    width: 20%;
                    margin-left: 0;
                }
                ul {
                    &:before {
                        content: '';
                        position: absolute;
                        height: 0px;
                    }
                    li {
                        width: 100%;
                    }
                }
            }
        }
    }
    .departments {
        margin-top: 0;
        padding: 0;
        border-left: $linewidth solid $menu-grey;
        border-radius: 0.25rem;
        &:last-child {
            border-left: $linewidth solid $white;
        }
        @include media-breakpoint-up(lg) {
            padding: 0 1.25rem;
            margin-top: 2.5rem;
            border-left: none;
        }
        >.card {
            border:1px solid $domas-orange;
            padding: 1rem;
            cursor: pointer;
            h5 {
                padding: 0;
                margin: 0;
                color: $domas-orange;
                font-size: 0.875rem;
                letter-spacing: 0.0625rem;
                text-transform: uppercase;
            }
        }
        &:first-child {
            &:before {
                content: "";
                position: absolute;
                top: -$linespace;
                height: 2.5rem;
                width: $linewidth;
                left: 50%;
                background: $menu-grey;
                margin-top: 0.125rem;
            }
        }
        ul {
            display: none;
            @include media-breakpoint-up(lg){
                display: block;
            }
            li {
                ul {
                    display: block;
                    padding-left: 2.5rem;
                    @include media-breakpoint-up(lg){
                        padding-left: 0;
                    }
                    li {
                        .card {
                            &:before {
                                content: "";
                                position: absolute;
                                top: -$linespace;
                                height: 2.5rem;
                                width: $linewidth;
                                left: 50%;
                                background: $menu-grey;
                                margin-top: 0.125rem;
                            }
                        }
                    }
                }
            }
        }
        &:last-child {
            ul {
                li {
                    .card {
                        &:before {
                            content: "";
                            position: absolute;
                            top: -$linespace;
                            height: 2.5rem;
                            width: $linewidth;
                            left: 50%;
                            background: $menu-grey;
                            margin-top: 0.125rem;
                        }
                    }
                }
            }
        }
    }
    .card {
        border:1px solid $menu-grey;
        padding: 0;
        margin-bottom: 2.5rem;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        }
        .card-block {
            padding: 1.12rem;
            .card-title {
                font-size: 0.875rem;
                margin-bottom:0.3125rem;
                @include franklin-light;
                &:first-child {
                    @include franklin-demi;
                }
            }
        }
        &:before {
            content: "";
            position: absolute;
            top: -$linespace;
            height: 0;
            width: $linewidth;
            left: 50%;
            background: $menu-grey;
            margin-top: 0.125rem;
            @include media-breakpoint-up(lg){
                height: 2.5rem;
            }
        }
        &.director {
            width: 100%;
            max-width: 100%;
            margin: 0 auto 2.5rem;
            @include  media-breakpoint-up(lg){
                margin: 0 auto 2.5rem;
                max-width: 320px;
            }
            img {
                width: 100%;
                height: auto;
            }
            &:before {
                content: "";
                position: absolute;
                width: 0;
            }
            &:after {
                content: "";
                position: absolute;
                bottom: -2.5rem;
                height: 0rem;
                width: $linewidth;
                left: 50%;
                margin-left: -1px;
                background: $menu-grey;
                @include  media-breakpoint-up(lg){
                    bottom: -2.5rem;  
                }
            }
        }
    }
}
