.btn {
    padding: 0.5rem 1.3rem;
    margin-right:0rem;
    margin-top: 0rem;
    width: 100%;
    margin-bottom: 1.5rem;
    cursor: pointer;
    @include media-breakpoint-up(xl){
        width: auto;
        margin-right:2rem;
        margin-bottom: 1.5rem;
    }
}
.orange {
    font-size: 0.8rem;
    letter-spacing: 0.050rem;
    text-transform: uppercase;
    border: 1px solid $domas-orange;
    color: $domas-orange;
    background: transparent;
    opacity: 1;
    &:hover, &:focus {
        box-shadow: 0 0 0.5rem fade-out($domas-orange, 0.8);
        color: $white;
        opacity: 0.8;
        background: $domas-orange;
    }
    &.solid-fill {
        box-shadow: 0 0 0.5rem fade-out($domas-orange, 0.8);
        color: $white;
        opacity: 0.8;
        background: $domas-orange;
    }
}

.arr-btn {
    display: inline-block;
    width: 100%;
    &:hover, &:focus {
        opacity: 1;
        background: transparent;
        box-shadow: none;
    }
    &.link-btn {
        height: 30px;
        margin-top: 0.937rem;
        img {
            display: initial;
            height: 35px;
            width: auto;
        }
    }
    &.orange {
        border: none;
        background-size: contain;
        &.sm-btn {

        }
        &.md-btn {
            width: 2.5rem;
        }
        &.lg-btn {
            width: 3.75rem;
        }
        &.link-btn {
            height: 30px;
        }
    }
}

#cf-reset {
    &:focus {
        background: transparent;
        color: $domas-orange;
        text-decoration: none;
    }
}