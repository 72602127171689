footer {
    padding: 3.75rem 1.25rem;
    background :$background-darkgrey;
    margin: 0px;
    color: $white;
    @include media-breakpoint-up(xl) {
        font-size: 0.8125rem;
        margin-left: -40px;
        margin-right: -40px;
    }
    ul {
        padding: 0px;
        li {
            list-style: none;
            padding-bottom: 0.625rem;
            a {
                color: $white;
                text-decoration: none;
                @include transition;
                &:hover {
                    color: $domas-orange;
                    text-decoration: none;
                }
            }
        }
        &.small-list {
            font-size: 0.625rem;
            li {
                 padding-bottom: 0.2rem;
            }
        }
    }
    .footer-blocks-inner {
        p {
        font-size: 0.687rem;
        margin: 0;
        padding: 0 0 0.687rem 0;
        }
    }
    .container {
        &:last-of-type {
            text-align: left;
            @include media-breakpoint-up(xl) {
                margin-top: 3.125rem;
                text-align: right;
            }
        }
        .row {
            .floleft {
                text-align: left;
                ul, a {
                    padding-left: 0rem;
                    @include media-breakpoint-up(lg){
                        padding-left: 1.25rem;
                    }
                }
            }
            [class^="col-xl-"] {
                &:last-child {
                    border-left: none;
                    @include media-breakpoint-up(xl) {
                        border-left: 1px solid $font-lightgrey;
                    }
                }
            }
        }
    }
    small {
        font-size: 0.625rem;
        text-align: left;
        font-weight: 100;
        a {
            color: $white;
            text-decoration: none;
            font-weight: 500;
            @include transition;
            &:hover {
                text-decoration: none;
                color: $domas-orange;
            }
        }
        @include media-breakpoint-up(xl) {
            font-size: 0.8125rem;
            text-align: right;
             a {
                color: $white;
                text-decoration: none;
                font-weight: 500;
                @include transition;
                &:hover {
                    text-decoration: none;
                    color: $domas-orange;
                }
            }
        }
    }
}

ul {
    &.social-links {
        margin-bottom: 0;
        padding-bottom: 0;
        display: inline-block;
        margin-bottom: 2.5rem;
        margin-top: 1.2rem;
        @include media-breakpoint-up(xl) {
            margin-top: 0;
            margin-bottom: 0;
        }
        li {
            display: inline-block;
            font-size: 18px;
            margin-right: 1rem;
            a {
                i {
                    color: $white;
                    text-decoration: none;
                    cursor: pointer;
                    @include transition;
                    &:hover {
                        color: $domas-orange;
                    }
                }
            }
        }
    }
}

.narrow-gutter {
    margin-left: -20px;
    margin-right: -20px;
    & > [class^="col-"] {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.link {
    border: 0px;
    text-transform: none;
    &:hover {
        text-decoration: none;
        background: transparent;
    }
    &.orange {
        color: $domas-orange;
    }
    i {
        font-size: 15px;
        margin-top: 5px;
        vertical-align: text-bottom;
    }
}

#back-to-top:focus {
    background: transparent;
}