/*vendors*/
@import 'bootstrap/bootstrap';
@import 'font-awesome/scss/font-awesome';

/*components*/
@import 'components/fonts';
@import 'components/colors';
@import 'components/global-variables';
@import 'components/buttons';
@import 'components/forms';
@import 'components/modals';
@import 'components/nav';
@import 'components/header'; 
@import 'components/slider';
@import 'components/grid-slider';
@import 'components/row-slider';
@import 'components/column-section';
@import 'components/materials';
@import 'components/casestudies';
@import 'components/treestructure';
@import 'components/news';
@import 'components/support';
@import 'components/footer';
@import 'components/background_types';

