.col-briefs-holder{
    text-align: center;
    .small-gutter {
        [class^="col-"]{
            display:flex;
        }
    }
    h1 {
        margin-bottom: 3.175rem;
        text-align: left;
        @include media-breakpoint-up(xl){
            text-align:center;
        }
    }
    .download-holder {
        border: 1px solid $menu-grey;
        padding: 1.87rem;
        flex: 1;
        text-align: left;
        margin-bottom: 1.25rem;
        padding-bottom:80px;
        h5 {
            @include franklin-demi;
            font-size: 0.875rem;
            margin-bottom: 1.25rem;
        }
        .file-download {
            color: $domas-orange;
            font-size: 2.5rem;
            text-align: center;
            position: absolute;
            bottom: 0;
            padding: 0 0 1.25rem;
            width: 100%;
            margin-left: -1.87rem;
            .icon-blue {color: #3a5fcd!important; }
            .icon-red { color: $domas-orange!important;}
            .icon-green{ color: #02723B!important; }

        }
        p {
            font-size: 0.875rem;
            line-height:1.25rem;
        }
    }
    .btn {
        width: 100%;
        max-width: 300px;
        margin: 2.5rem auto 0;
    }
}