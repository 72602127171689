.header-content-holder-filters {
    position: relative;
    a {
        border: none;
        position: absolute;
        top: 0;
        right: 15px;
        
        &:hover {
            background: transparent;
            color: $font-grey;
            text-decoration: none;
            box-shadow: none;
            .cross {
                &:before, &:after {
                    background-color:$font-grey;
                }
            }
        }
        .cross {
            position: relative;
            right: 0px;
            top: 0px;
            width: 10px;
            height: 10px;
            opacity: 1;
            margin-left: -5px;
            display: inline-block;

            &:hover {

            }
            &:before, &:after {
                position: absolute;
                content: ' ';
                height: 12px;
                background-color:$domas-orange;
                

            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
            &.active {
                &:before, &:after {
                    background-color:$domas-orange;

                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
.col-casestudies-holder {
    background: transparent;
    text-align: center;
    a {
        color: $font-grey;
        text-decoration: none;
    }
    .card {
        background: $white;
        padding: 0;
        margin-bottom: 2.5rem;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        }
        .cs-img-holder {
            width: 100%;
            height: 265px;
        }
        .card-block {
            padding: 1.12rem;
            .card-title {
                font-size: 0.875rem;
                margin-bottom:0.3125rem;
                @include franklin-light;
                &:first-child {
                    @include franklin-demi;
                }
            }
        }
    }
    .btn {
        width: 100%;
        max-width: 300px;
        margin: 2.5rem auto;
        &.orange {
            color: $domas-orange;
            &:hover, &:focus {
                color: $white;       
            }
        }
    }
}

.dd {
    // padding-bottom: 1.25rem;
    select {
        max-width: 100%;
        margin-top: 1.25rem;
    }
    // &:after {
    //     content: '\f107';
    //     font-family: 'fontawesome';
    //     color: $font-grey;
    //     width: 0.93rem;
    //     height: 0.93rem;
    //     position: absolute;
    //     right: 25px;
    //     top: 50%;
    //     margin-top: -12px;
    // }
}