html {
    background: $background-grey;
}

body {
    background: none;
    color: $font-grey;
    @include franklin-light;
}

h1 {
    color: $domas-orange;
    margin-bottom: 2.125rem;
    @include franklin-light;
    font-size: 1.3rem;
    @include media-breakpoint-up(xl){
        font-size: 1.6rem;
    }
    &.white-title {
        color: $white;
    }
}

h2 {

}

h3 {
    font-size: 0.875rem;
    margin-bottom: 0.875rem;
    color: $menu-grey;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    @include franklin-heavy;
}

h4 {
    font-size: 1.1rem;
    margin-bottom: 1.25rem;
    @include franklin-light;
}

h5 {
    margin: 0;
    font-size: 1.25rem;
    @include franklin-light;
}

h6 {
    margin: 0;
    font-size: 1rem;
    @include franklin-light;
}

p {
    @include franklin-light;
    font-size:1rem;
    line-height: 1.4rem;
}

a {
    color: $domas-orange;
    &:hover {
        color: darken($domas-orange, 5%);
        text-decoration: none;
    }
}

@mixin transition() {
    transition: all 0.6s;
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background: $background-grey;
    padding: 0px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
        padding: 0px 40px;
    }
    
}

.section {
    position: relative;
    background: $white;
    width: 100%;
    margin: 0 0 0;
    &.nomt {
        margin-top: 0;
    }
    &.nopt {
        padding-top: 0;
    }
    &.mt {
        margin-top: 1.25rem;
    }
    @include media-breakpoint-up(lg) {
        margin: 1.25rem 0 0;
    }
}

.card {
    background: transparent;
    border: none;
    .card-block {
        h4 {
            &.card-title {
                font-size: 12px;
            }
        }
    }
}


.dark-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: fade-out($background-darkgrey, 0.1);
}

.small-gutter {
    $small-gutter: $grid-gutter-width-sbase / 2;
    margin-left: -$small-gutter;
    margin-right: -$small-gutter;
    [class^="col-"] {
        padding-left: $small-gutter;
        padding-right: $small-gutter;
    }
}

.cross-std {
    position: relative;
    right: 0px;
    top: 0px;
    width: 10px;
    height: 10px;
    opacity: 1;
    margin-left: -5px;
    display: inline-block;
    cursor: pointer;
    &:before, &:after {
        position: absolute;
        content: ' ';
        height: 12px;
        width: 1px;
        background-color:$domas-orange;
        

    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &.active {
        &:before, &:after {
            background-color:$domas-orange;

        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}