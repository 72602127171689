.modal {
    .modal-dialog {
        .modal-content {
            border-radius: 0;
            padding: 1.25rem 1.25rem 0;
            .modal-header {
                position: relative;
                padding:0 0 1.25rem 0;
                h1 {
                    margin-bottom: 0;
                }
            }
            .modal-body {
                padding:1.25rem 0 0;
                p {
                    padding-top: 0px;
                    padding-bottom: 0.650rem;
                }
            }
            .modal-footer {
                padding: 1.25rem 0 0;
                button {
                    
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}