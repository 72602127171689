$background-grey:     #f4f4f4;
$background-darkgrey: #231F20;
$font-lightgrey:      #918f8f;
$font-grey:           #4F4C4D;
$menu-grey:           #D3D2D2;

// brand fonts
$domas-orange:        #E2231A;

//site Colours
$blue:                #1A95E2;
$green:               #5EBC7F;
$yellow:              #E8A100;
$red:                 #E2231A;