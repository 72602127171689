header {
    padding: 2.5rem 0rem;
    background: $white;
    max-width: 1300px;
    h1 {
        padding-right: 0.625rem;
        margin-bottom: 0;
        @include media-breakpoint-up(xl) {
            padding-right: 0px;
         }
    }
    .header-content-holder {
        border-left:1px solid lighten($menu-grey, 10%);
        padding: 0rem 0 0rem 1.25rem;
        color: $font-grey;
    }
    p {
        color: $font-grey;
        padding: 0;
        margin: 0;
    }
}
.text-description {
    border-left: 1px solid $font-grey;
    padding-left: 0.625rem;
}