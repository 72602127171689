.section {
    &.col-section {
        padding: 3.75rem 0;
        color: $white;
        @include media-breakpoint-up(xl) {
            padding: 5.938rem 0;
        }
    }
    .col-img-holder {
        min-height: 17.44rem;
        @include media-breakpoint-up(xl){
            padding: 0;
            min-height: auto;
        }
    }
    .col-img-holder-slider {
        min-height: 17.44rem;
        padding: 0;
        @include media-breakpoint-up(xl){
            min-height: auto;
            padding: 0;
        }
    }
    .col-logo-holder {
        border-right:none;
        p {
            margin-top: 3.75rem;
            margin-bottom: 3.75rem;
            padding-bottom: 3.75rem;
            border-bottom: 1px solid $white;
            strong {
                @include franklin-demi();
                display: block;
                clear: both;
            }
        }
        img {
            max-width: 9.438rem;
        }
        @include media-breakpoint-up(xl){
            min-height: auto;
            border-bottom: none;
            border-right: 1px solid $white;
            p {
                border-bottom: none;
            }
            img {
                max-width: 18.56rem;
            }
        }
    }

    &.col-partners-holder {
        background: transparent;
        padding: 3.75rem 1.25rem;
        img {
            width: 100%;
            padding-bottom: 1.875rem;
        }
        @include media-breakpoint-up(lg){
            padding: 5.938rem 0 0;
        }
    }

    .col-materials-holder {
        min-height: 17.44rem;
        overflow: hidden;
        position: relative;
        @include media-breakpoint-up(xl){
            min-height: auto;
        }
    }
    
    &.col-company-holder {
        padding: 3.125rem 0;
    }

    &.col-news-holder {
        background: transparent;
    }

    &.col-briefs-holder {
        padding: 3.75rem 1.25rem;
        @include media-breakpoint-up(xl) {
            padding:3.75rem 0 5.938rem;
        }
    }
    &.col-casestudies-holder {
        padding: 0 1.25rem;
        @include media-breakpoint-up(xl) {
            padding:0rem 0 5.938rem;
        }
    }
}

.main-column-holder {
    margin: 0rem 1.25rem;
    @include media-breakpoint-up(xl){
        margin: o;
    }
}

.match-height {
    padding: 2.5rem 0;
    @include media-breakpoint-up(xl) {
        padding: 5.938rem 0;
    }
}

.news-section {
    padding: 3.125rem 1.25rem;
    width: 100%;
    background: transparent;
    @include media-breakpoint-up(xl){
        padding: 9.375rem 0;
    }
    .inner-border {
        padding: 1.25rem 0;
        border-bottom: 1px solid $menu-grey;
        &:first-child {
            border-top: 1px solid $menu-grey;
        }
        h5 {
            font-size: 0.875rem;
            @include media-breakpoint-up(xl){
                font-size: 1.25rem;
            }
        }
        .date {
            margin-top: 1.25rem;
            letter-spacing: 0.065rem;
            font-size: 0.625rem;
            @include media-breakpoint-up(xl) {
                margin-top: 0;
                font-size: 0.937rem;
            }
        }
        .arr-btn {
            vertical-align: text-top;
            @include media-breakpoint-up(xl) {
                vertical-align: none;
            }
            img {
                margin-top: 1.25rem;
                width: 100%;
                max-width: 1.25rem;
                width: 1.25rem;
                float: right;
                @include media-breakpoint-up(xl) {
                    margin-top: 2px;
                }
            }
        }
    }
}

.quote-holder {
    padding: 1.25rem 2.5rem;
    @include media-breakpoint-up(lg){
        padding: 2.5rem 0 2.5rem 2.5rem;
    }
    blockquote {
        font-size: 1.5rem;
        font-weight: 300;
        padding:1.25rem 0;
        border-bottom: 1px solid lighten($menu-grey, 5%);
        border-right: none;
        @include media-breakpoint-up(lg){
        border-right: 1px solid lighten($menu-grey, 5%);
        border-bottom: none;
        padding:0 1.25rem;
        }
    }
}

p {
    &.contact-content {
        padding-bottom: 2rem;
    }
}