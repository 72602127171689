

.row-slider {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 1.25rem;
    @include media-breakpoint-up(xl){
        margin-bottom: 3.75rem;
    }
    &.nomt {
        margin-top: 0;
    }
    &.nopt {
        padding-top: 0;
    }
    .slick-slide {
        &:after {
            content: "";
            position: absolute;
            top: 1.25rem;
            height: calc( 100% - 2.5rem );
        }
        &.slick-current {
            .card {
            border-radius: 0px;
            padding-bottom: 10px;
            border-bottom: 4px solid $domas-orange;
            }
        }
    }
    .row-slide {
        padding:1.25rem 2.5rem;
        position: relative;
        @include media-breakpoint-up(xl){
            padding: 1.25rem;
        }
        .card {
            .card-block {
                background: $white;
                padding: 1.375rem;
                border-left:0.3125rem solid $menu-grey;
                h5 {
                    font-size:1rem;
                    text-align: center;
                }
                &.blue {
                    border-color:$blue;
                }
                &.green {
                    border-color: $green; 
                }
                &.yellow {
                    border-color: $yellow;
                }
                &.red {
                    border-color: $red;
                }

            }
            .bg-img {
                position: relative;
                min-height: 210px;
                width: 100%;
            }
        }
    }
    @include media-breakpoint-up(xl){
        
    }
}

.row-slider-holder {
    .slider-content-holder {
        padding: 1.25rem;
        color: $white;
        @include media-breakpoint-up(xl){
            padding: 0;
        }
    }
    p {
        color: $white;
        padding: 0;
        margin: 0;
    }
}


.grid-arrows-section {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2.5rem;
    .arrowshere{
        display: inline-block;
        padding:0 1.25rem 2.5rem;
        margin: 0 auto;
        @include media-breakpoint-up(xl) {
            padding:0 2.5rem 2.5rem;
        }
        .slider-arrow {
                position:relative;
                bottom: 0px;
                z-index: 8;
                height: 29px;
                cursor: pointer;
                margin-bottom: -10px;
                opacity: 0.5;
                display: inline-block;
                @include transition();
                &.slick-prev {
                    &:before {
                        content: "";
                        position: absolute;
                        width: 200%;
                        height: 200%;
                        left: -50%;
                        z-index: -1;
                        background: url('assets/img/ARROWRED.png')no-repeat center;  
                        background-size: contain;
                        -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                        @include media-breakpoint-up(xl) {
                            background-size: auto;
                        }
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
                &.slick-next {
                    &:before {
                        content: "";
                        position: absolute;
                        width: 200%;
                        height: 200%;
                        left: -50%;
                        z-index: -1;
                        background: url('assets/img/ARROWRED.png')no-repeat center;  
                        background-size: contain;
                        @include media-breakpoint-up(xl) {
                            background-size: auto;
                        }
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
            }

        .slick-next, .slick-prev {
            color: $white;
            width: 1.5rem;
            outline: none;
            border: 0px;
            @include media-breakpoint-up(xl){
                width: 3.75rem;
            }
        }
        .dotshere {
            display: inline-block;
            padding: 0 2.5rem;
            @include media-breakpoint-up(xl) {
                padding: 0 3.75rem;
            }
            ul {
                &.slickLinesGen {
                    width: auto;
                    padding: 0;
                    position: relative;
                    display: inline-block;
                    margin: 0 auto;
                    text-align: center;
                    z-index: 8;
                    li {
                        border-bottom: 1px solid fade-out($font-lightgrey, 0.6);
                        margin:0 3px;
                        display: inline-block;
                        width: 1.25rem;
                        margin-top: -10px;
                        &.slick-active {
                            border-bottom: 1px solid $font-lightgrey;
                        }
                        button {
                            background: transparent;
                            color: transparent;
                            border: transparent;
                            outline: none;
                        }
                    }
                }
            }
        }
    }
    p {
        margin-top: 2.5rem;
        text-align: left;
        @include media-breakpoint-up(md){
            text-align:center;
        }
    }
}


.nvdesc {
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 3.98rem;
    margin-top: 0rem;
    p {
        width: 80%;
        margin: 0 auto;
    }
}