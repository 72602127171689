.col-news-holder {
    padding:0 1.25rem 0rem;
    @include media-breakpoint-up(xl){
        padding: 0 0 0 0 ;
    }
    .row {
        margin-left: -1.25rem;
        margin-right: -1.25rem;
        .orange {
            margin: 0 auto;
        }
        [class^="col-"] {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            padding-bottom: 2.5rem;
            display: flex;
            .row {
                [class^="col-"] {
                    padding-bottom: 0;
                }
            }
        }
    }

    .img-top {
        width: 100%;
        min-height: 210px;
    }
    .card {
        background: $white;
        flex:1;
    }
    .card-block {
        padding: 2rem;
    }

}

.news-content {
    padding-bottom: 80px!important;
}

.liker-holder {
    display: inline-block;
    padding: 0 0 3rem;
    position: absolute;
    bottom: 0;
    &.article-liker {
        position: relative;
        margin-top: 1.25rem;
        padding-top: 1.25rem;
        padding-bottom: 0;
        border-top:1px solid lighten($menu-grey, 10%);
        width: 100%;
    }
    .liker {
        color: $domas-orange;
        display: inline-block;
        font-size: 1.25rem;
        cursor: pointer;
        padding-right: 1.25rem;
        i {
            padding: 0 0 0 1px;
        }
        &:hover {
            color: darken($domas-orange, 5%);
        }
        .num-likes {
            font-size: 0.525rem;
            display: inline-block;
            margin-left: -0.250rem;
        }
    }
    .animating {
        animation: heart-burst .5s steps(28) 1;
    }

    @keyframes heart-burst {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        50% { 
            transform: scale(1.3);
            opacity: 0;
        }
        75% { 
            transform: scale(0);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    .read-btn {
        color: $font-lightgrey;
        font-size: 0.625rem;
        text-transform: uppercase;
        text-decoration: none;
        @include transition();
        i {
            font-size: 1.25rem;
            padding-right: 0.312rem;
        }
        &:hover {
            color: darken($font-lightgrey, 10%);
        }
    }
}