select {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
    border: 1px solid $font-lightgrey;
    background: transparent;
    color: $font-lightgrey;
    padding: 0.6rem 1.3rem;
    font-size: 0.73rem;
    line-height: 0.73rem;
    letter-spacing: 0.06rem;
    text-transform: uppercase;
    width: 100%;
    background: url('assets/img/ARROWUD.png')no-repeat;
    background-position: 95%;  
    background-size: 12px;
    outline: none;
    @include media-breakpoint-up(md){
        float: none;
    }
}

#general-contact {
    input[type="text"], textarea {
        -webkit-appearance: none;
        width: 100%;
        padding: 0.6rem 1.3rem;
        border: 1px solid $font-grey;
        &:focus {
            border: 1px solid $domas-orange;
        }
        &::-webkit-input-placeholder {
            font-size: 0.8rem;
        }
        &::-moz-placeholder { 
            font-size: 0.8rem;
        }
        &:-ms-input-placeholder { 
            font-size: 0.8rem;
        }
        &:-moz-placeholder { 
            font-size: 0.8rem;
        }
    }
    input[type="checkbox"]{
        margin-right: 0.5rem;
    }
    textarea {
        height: 100px;
    }
}

.full-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: fade-out(black, 0.5);
    z-index: 9999;
    display: none;
}
#successMessage {
    position: fixed;
    top: 0;
    margin-top: 1.25rem;
    height: auto;
    right: 50%;
    margin-right: -45%;
    text-align: center;
    width: 90%;
    padding: 40px;
    background: $white;
    font-size: 21px;
    display: none;
    z-index: 9999;
    @include media-breakpoint-up(lg){
        top: 50%;
        margin-top: -150px;
        height: 300px;
        right: 50%;
        margin-right: -25%;
        text-align: center;
        width: 50%;
    }
    .closethis {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
        color: $menu-grey;
        cursor: pointer;
        @include transition;
        &:hover {
            color: $font-lightgrey;
        }
    }
}

#main-search {
    padding: 1.25rem 0 0;
    background: none;
    display: none;
    @include media-breakpoint-up(lg){
        padding: 1.25rem 0;
    }
    input[type="text"] {
        background: $white;
        width: 100%;
        border-radius: 0;
        padding: 0.87rem;
        outline: 0;
        &:focus {
            border-color: $domas-orange;
            outline: 0;
        }
    }
    #search-results {
        padding: 2.5rem 0 0;
        display: none;
        position: relative;
        .closethis {
            position: absolute;
            top: 2.5rem;
            right:0;
            color: $menu-grey;
            z-index: 888;
            cursor: pointer;
            @include transition;
            &:hover {
                color: $font-lightgrey;
            }
        }
        .header-content-holder {
            border-left: 1px solid fade-out($menu-grey, 0.3);
        }
        ul {
            padding:0 1.25rem;
            margin: 0;
            li {
                list-style: none;
                margin-bottom: 0.5rem;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 15px;
                    background: url(assets/img/ARROWDARK.png) no-repeat center;
                    background-size: 20px;
                    right: -25px;
                    top: 50%;
                    margin-top: -9px;
                    display: none;
                    @include transition;
                    @include media-breakpoint-up(md){
                        right: 0px;
                        display: block;
                    }
                }
                &:hover {
                    @include transition;
                    &:after {
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 15px;
                    background: url(assets/img/ARROWDARKR.png) no-repeat center;
                    background-size: 20px;
                    right: -25px;
                    top: 50%;
                    margin-top: -9px;
                    display: none;
                    @include media-breakpoint-up(md){
                            right: 0px;
                            display: block;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    font-weight: 300;
                    color: $font-grey;
                    @include transition;
                    &:hover {
                        color: $domas-orange;
                    }
                }
            }
        }
    }
}