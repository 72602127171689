nav {
    position: relative;
    background: $white;
    padding: 1.25rem 0rem;
    z-index: 9999;
    @include media-breakpoint-up(xl) {
        padding-bottom: 0;
    }
    img {
        &.logo-img {
            width: 100%;
            max-width: 99px;
            max-height: 40px;
            position: absolute;
            vertical-align: middle;
            top: 50%;
            margin-top: -20px;
            cursor: pointer;
        }
    }
}


.top-nav {
    border-left: 1px solid lighten($menu-grey, 10%);
    text-align: center;
    font-size: 0.75rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    color: $font-lightgrey;
    white-space: nowrap;
    display: inline-flex;
    margin-bottom: 0;
    height: 50px;
    vertical-align: middle;
    line-height: 1rem;
    padding-left: 0.05rem;
    &.menu-block {
        padding-left: 0.65rem;
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 1.25rem;
        height: auto;
        padding-left: 0rem;
    }
    img {
        height: 80%;
        margin-top: 3px;
    }
    ul {
        padding: 0;
        margin:0;
        display: flex;
        li {
            list-style: none;
            flex: 1;
            text-align: center;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            &:first-child {
                padding-top: 0;
            }
            a {
                color: $font-lightgrey;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 0.05rem;
                @include transition();
                &:hover {
                    color: $domas-orange;
                }
            }
        }
    }
    .tel-box {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 8px;
        @include media-breakpoint-up(xl){
            padding-top: initial;
        }
    }
    .searcher {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        line-height: 1rem;
    }
}

// Navigation Hamburger
.cmn-toggle-switch {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    float: right;
    text-align: right;
    padding: 0;
    width: 2.8rem;
    height: 3.438rem;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background: transparent;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    margin-top: -7px;
    @include media-breakpoint-up(xl){
        margin-top: 0;
    }
    &:focus {
        outline: none;
    }
    span {
        display: block;
        position: absolute;
        top: 1.5rem;
        left: 0.6rem;
        right: 0rem;
        height: 0.0625rem;
        background: $font-lightgrey;
        &:before, &:after {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 0.0625rem;
            background-color: $font-lightgrey;
            content: "";
        }
        &:before {
            top: -0.6rem;
        }
        &:after {
            bottom: -0.6rem;
        }
    }
}

.cmn-toggle-switch_hamburger {
    span {
        -webkit-transition: background 0 0.3s;
        transition: background 0 0.3s;
        &:before, &:after {
            -webkit-transition-duration: 0.3s, 0.3s;
            transition-duration: 0.3s, 0.3s;
            -webkit-transition-delay: 0.3s, 0;
            transition-delay: 0.3s, 0;
        }
        &:before {
            -webkit-transition-property: top, -webkit-transform;
            transition-property: top, transform;
        }
        &:after {
            -webkit-transition-property: bottom, -webkit-transform;
            transition-property: bottom, transform;
        }
    }
    &.active {
        span {
            background: none;
            &:before, &:after {
                -webkit-transition-delay: 0, 0.3s;
                transition-delay: 0, 0.3s;
            }
            &:before {
                top: 0;
                background: $domas-orange;
                transition: all 0.3s;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &:after {
                bottom: 0;
                background: $domas-orange;
                transition: all 0.3s;
                -webkit-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
    }
}

#main-nav {
    position: absolute;
    top: 0;
    max-width: 570px;
    width: 80%;
    right: -90%;
    height: 100%;
    background: fade-out($menu-grey, 0.02);
    z-index: 9998;
    @include transition();
    &.open {
        right: 0px;
    }
    @include media-breakpoint-up(xl) {
        position: relative;
        height: auto;
        background-color: $background-grey;
        padding-top: 0!important;
        width: 100%;
        max-width: 100%;
        right: auto;
    }
}

.nav-holder {
    overflow: hidden;
    @include media-breakpoint-up(xl){
        background: $background-grey;
        &:after {
        }
    }
    ul {
        padding: 0rem 1.063rem 1.875rem 2.5rem;
        @include media-breakpoint-up(xl) {
            margin-bottom: 0px;
            padding: 0px 10px;
            display: inline-block;
            
        }
        &.main-nav-list {
            padding-top: 1.25rem;
            @include media-breakpoint-up(xl) {
                padding-top: 0;
            }
            li {
                a {
                    &:after {
                        @include transition();
                        content: '';
                        position: absolute;
                        width: 25px;
                        height: 15px;
                        background: url(assets/img/ARROWDARK.png) no-repeat center;
                        background-size: 25px;
                        right: 0;
                        top: 0;
                        margin-top: 20px;
                        @include media-breakpoint-up(xl) {
                            display: none;
                        }

                    }
                    @include transition();
                    &:hover {
                        color: $domas-orange;
                        &:after {
                            @include transition();
                            content: '';
                            position: absolute;
                            width: 25px;
                            height: 15px;
                            background: url(assets/img/ARROWDARKR.png) no-repeat center;
                            background-size: 25px;
                            right: 0;
                            top: 0;
                            margin-top: 20px;
                        }
                    }
                }
                ul {
                    li {
                        a {
                            &:after {
                                content: '';
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        li {
            position: relative;
            list-style: none;
            border-bottom: 1px solid fade-out($white, 0.5);
            @include media-breakpoint-up(xl){
                display: inline-block;
                border-bottom: none;
                padding: 10px 10px;
            }
            &:last-child {
                border-bottom: none;
            }
            a {
                @include franklin-light();
                color: $font-grey;
                text-decoration: none;
                font-size: 1rem;
                padding: 1rem 0;
                display: block;
                letter-spacing: 1px;
                @include transition();
                &:hover {
                    color: $domas-orange;
                }
                &.promo-new {
                    span {
                        &:after {
                            content: "new";
                            font-size: 0.75rem;
                            margin-left: 0.3125rem;
                            // position: relative;
                            color: $domas-orange;
                            text-transform: uppercase;
                        }
                    }
                }
                @include media-breakpoint-up(xl) {
                    font-size: 0.875rem;
                    padding: 0;
                    letter-spacing: 0;
                }
            }
            ul {
                padding: initial;
                li {
                    list-style: none;
                    border-bottom: none;
                }
            }
        }
    }
    .mobile-sub-nav {
        li {
            position: relative;
            a {
                font-size: 0.8rem;
                padding: 0.8127rem 0;
            }
            &:first-child {
                padding-top: 0;
                a {
                    border-top:1px solid fade-out($white, 0.5);
                }
            }
            &:last-child {
                a {
                    border-bottom: 1px solid fade-out($white, 0.5);
                }
            }
            &:after {
                content: '';
                position: absolute;
                width: 20px;
                height: 15px;
                background: url('assets/img/ARROWREDSM.png')no-repeat center;
                right: 0;
                top: 50%;
                margin-top: -9px;
            }
        }
    }
    .nav-lower-blocks {
        padding: 0rem 1.063rem 1.875rem 2.5rem;
        .micro-nav {
            padding: 0;
            li {
                border-bottom: none;
                a {
                    font-size: 0.875rem;
                    padding: 0rem;
                }
                &:first-child {
                    padding-top: 0px;
                }
            }
        }
        .nav-blocks-inner{
            p {
                margin-top: 0;
                margin-bottom: 0.5rem;
                font-size: 0.87rem;
                strong{
                    display: block;
                }
            }
        }
    }
}

.dropdown {
    position: relative;
    padding: 0px;
    .closer {
        position: absolute;
        right: 40px;
        z-index: 9999;
    }
    .block-link {
        padding: 0px;
    }
    .card {
        border-radius: 0px;
        .card-header {
            height: 5rem;
            border-radius: 0px;
        }
        .card-block {
            padding: 1.25rem 0;
        }
    }
}


.dropdown {
    .nav-hider {
        display: none;
        width: 100%;
    }
    ul {
        margin-left: -10px;
        margin-right: -10px;
        @include  media-breakpoint-up(xl){
            display: none;
        }
        @include transition();
        &.open {
            display: flex;
        }
        li {
            padding-left:10px;
            padding-right: 10px;
            &:first-child {
                padding-top: 0;
            }
        }
    }
    a {
        &.top-lvl {
            cursor: default;
            @include media-breakpoint-up(xl){
                pointer-events: initial;
                cursor: pointer;
            }
        }
    }
}

.cross {
    position: relative;
    right: 0px;
    top: 16px;
    width: 30px;
    height: 27px;
    opacity: 1;
    display: block;
    @include transition();
    &:hover {
        &:before, &:after {
            background-color:fade-out($domas-orange, 0.3);
        }
    }
    &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 22px;
        width:   0.0625rem;
        background-color:fade-out($font-grey, 0.3);
        @include transition();
    }
    &:before {
        transform: rotate(90deg);
    }
    &:after {
        transform: rotate(0deg);
    }
    &.active {
        &:before, &:after {
            background-color:$domas-orange;
            @include transition();
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    @include media-breakpoint-up(xl) {
        display: none;
    }
}

#simple-menu {
    display: block;
    @include media-breakpoint-up(xl){
        display: none;
    }
}

