.grid-title-section {
    padding: 3.125rem 0 0;
    @include media-breakpoint-up(xl) {
        padding: 6.5rem 0 0;
    }
    h1 {
        margin-bottom: 0;
    }
    .btn {
        margin-top: 1.25rem;
        @include media-breakpoint-up(md) {
            margin: 0;
        }
        &.orange {
        }
    }
    form {
        margin-top: 1.625rem;
        @include media-breakpoint-up(xl) {
            margin-top: 0rem;
        }
    }

    select {
        width: 100%;
        float: right;
        background: url('assets/img/ARROWUD.png')no-repeat;
        background-position: 95%;  
        background-size: 12px;
        @include media-breakpoint-up(md){
            float: none;
            max-width: 100%;
        }
    }

}

.grid-slider {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 1.25rem 0 0;
    &.nomt {
        margin-top: 0;
    }
    &.nopt {
        padding-top: 0;
    }
    .slick-slide {
        &:after {
            content: "";
            position: absolute;
            top: 1.25rem;
            height: calc( 100% - 2.5rem );
            border-right: 1px solid fade-out($font-lightgrey, 0.8);
        }
    }
    .grid-slide {
        padding: 1.25rem;
        position: relative;
        height: 265px;
        .bg-img {
            position: absolute;
            width: calc( 100% - 2.5rem );
            height: calc( 100% - 2.5rem );
            top: 1.25rem;
            left: 1.25rem;
            z-index: -1;
        }
    }
    .slider-overlay {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: fade-out($domas-orange, 0.2);
        padding: 2.5rem 0;
        opacity: 0;
        text-align: center;
        h3 {
            color: $white;
            @include franklin-light();
        }
        h4 {
            @include franklin-demi();
            font-size: 1.3rem;
            margin-bottom: 0.937rem;
        }
        p {
            font-size: 0.937rem;
            line-height: 1.125rem;
        }
        @include transition();
        &:hover {
            opacity: 1;
        }
    }
}

.grid-slider-holder {
    .slider-content-holder {
        padding: 1.25rem;
        color: $white;
        @include media-breakpoint-up(xl){
            padding: 0;
        }
    }
    p {
        color: $white;
        padding: 0;
        margin: 0;
    }
}


.grid-arrows-section {
    text-align: center;
    margin-bottom: 2.5rem;
    .arrowshere{
        display: inline-block;
        padding:0 1.25rem 2.5rem;
        margin: 0 auto;
        @include media-breakpoint-up(xl) {
            padding:0 2.5rem 2.5rem;
        }
        .slider-arrow {
                position:relative;
                bottom: 0px;
                z-index: 8;
                height: 29px;
                cursor: pointer;
                margin-bottom: -10px;
                opacity: 0.5;
                display: inline-block;
                @include transition();
                &.slick-prev {
                    &:before {
                        content: "";
                        position: absolute;
                        width: 200%;
                        height: 200%;
                        left: -50%;
                        z-index: -1;
                        background: url('assets/img/ARROWRED.png')no-repeat center;  
                        background-size: contain;
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                        transform: rotate(180deg);
                        @include media-breakpoint-up(xl) {
                            background-size: auto;
                        }
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
                &.slick-next {
                    &:before {
                        content: "";
                        position: absolute;
                        width: 200%;
                        height: 200%;
                        left: -50%;
                        z-index: -1;
                        background: url('assets/img/ARROWRED.png')no-repeat center;  
                        background-size: contain;
                        @include media-breakpoint-up(xl) {
                            background-size: auto;
                        }
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
            }

        .slick-next, .slick-prev {
            color: $white;
            width: 1.5rem;
            outline: none;
            border: 0px;
            @include media-breakpoint-up(xl){
                width: 3.75rem;
            }
        }
        .dotshere {
            display: inline-block;
            padding: 0 2.5rem;
            @include media-breakpoint-up(xl) {
                padding: 0 3.75rem;
            }
            ul {
                &.slickLinesGen {
                    width: auto;
                    padding: 0;
                    position: relative;
                    display: inline-block;
                    margin: 0 auto;
                    text-align: center;
                    z-index: 9999;
                    li {
                        border-bottom: 1px solid fade-out($font-lightgrey, 0.6);
                        margin:0 3px;
                        display: inline-block;
                        width: 1.25rem;
                        margin-top: -10px;
                        &.slick-active {
                            border-bottom: 1px solid $font-lightgrey;
                        }
                        button {
                            background: transparent;
                            color: transparent;
                            border: transparent;
                            outline: none;
                        }
                    }
                }
            }
        }
    }
}