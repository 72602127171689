$franklin-main: "franklin-gothic-urw",sans-serif;

@mixin font-weight($weight) {
  $weights: (
    light: 100,
    demi: 300,
    heavy: 500,

  );
  
  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }
  
  font-weight: $output;
}

@mixin franklin-light {
    font-family: $franklin-main;
    @include font-weight(light);
}

@mixin franklin-demi {
    font-family: $franklin-main;
    @include font-weight(demi);
}

@mixin franklin-heavy {
    font-family: $franklin-main;
    @include font-weight(heavy);
}

@font-face {
    font-family: 'fontello';
    src: url('css/fonts/fontello.eot');
    src: url('css/fonts/fontello.eot#iefix') format('embedded-opentype'),
          url('css/fonts/fontello.woff') format('woff'),
          url('css/fonts/fontello.ttf') format('truetype'),
          url('css/fonts/fontello.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

  .read-icon {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
}